import React from "react"

const CurrentYear = new Date().getFullYear();

const Footer = () => (
  <footer className={'c-footer [ o-page__footer ]'}>
      <div className="o-container">
          <div className={'c-footer__legal'}>
              <div>&copy; {CurrentYear} The App Office BV<br />BTW BE0525.690.708</div>
              <a href={'/algemene_voorwaarden.pdf'} className={'u-link'}>Terms & Conditions</a>
          </div>
      </div>
  </footer>
)

export default Footer
