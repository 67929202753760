import Image from "./image";
import Fade from "react-reveal/Fade";
import React from "react";

const Office = () => (
    <div className={'o-section o-section--flush [ c-office ]'}>
        <Image imagePath="office.jpg" imageAlt="The App Office" />
        <div className={'c-office__wrapper o-container'}>
            <Fade delay={200}>
                <h2 className={'c-office__title'}>The App Office</h2>
            </Fade>
            <div className={'c-office__address'}>
                <p>Lummense Kiezel 51, <br />3500 Hasselt, Belgium</p>
                <a href="https://g.page/Parc51?share" className={`u-link`} title="View on map">View on map</a>
            </div>
        </div>
    </div>
)

export default Office;
