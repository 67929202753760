import React from "react"
import { Link } from "gatsby"
import Logo from "../images/tao.svg";

const Header = () => {
    return (
        <header className={'c-header [ o-page__header ]'}>
            <div className="c-header__container o-container">
                <Link to={'/'} title={`Back to homepage`} className={'c-logo__link'}>
                    <img className={`c-logo`} src={Logo} alt={`The App Office logo`}/>
                </Link>

                <Link to={'/'} className={'c-logo__text-link'}>
                    The App Office
                </Link>

                <nav className={`c-menu`}>
                    <ul className={`c-menu__list`}>
                        <li className={`c-menu__item `}><Link className={`c-menu__item-link`} activeClassName={'u-link--active c-menu__item-link--active'}
                                                              to={`/`}>Home</Link></li>
                        <li className={`c-menu__item `}><Link className={`c-menu__item-link`} activeClassName={'u-link--active c-menu__item-link--active'}
                                                             to={`/services/`}>Services</Link></li>
                        <li className={`c-menu__item`}><Link to={`/contact/`} className={`c-menu__item-link`}
                                                             activeClassName={'u-link--active c-menu__item-link--active'}>Contact</Link>
                        </li>
                    </ul>
                </nav>

            </div>
        </header>
    );
};

export default Header
