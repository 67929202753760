import Fade from "react-reveal/Fade";
import React from "react";
import {graphql, StaticQuery} from "gatsby"

const Contact =  ({ data }) => {
    const content = data.prismicHero.data;
    return (
        <div className="o-section o-section--flush [ c-contact ]" id={`contact`}>
            <div className={`o-container`}>
                <Fade delay={200}>
                    <h3 className={'c-hero__subtitle u-h6'}>{content.leading_title}</h3>
                    <h2 className={'c-hero__title u-h2'}>{content.title.text}</h2>
                    <div dangerouslySetInnerHTML={{ __html: content.description.html }} />
                </Fade>
            </div>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
          query {
            prismicHero(uid: {eq: "contact"}) {
            data {
              description {
                html
                text
              }
              leading_title
              title {
                html
                text
              }
            }
          }
      }
    `}
        render={data => <Contact data={data} {...props} />}
    />
)
